@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet');
select.form-control,
textarea.form-control,

input.form-control {
  font-size: small;
  font-family: 'Poppins', sans-serif;

}

label.control-label{
  font-size:small;
}

input[type=file]{
  width: 100%;
}

h1,h2,h3,h4,h5,h6,label,span {
  font-family: 'Poppins', sans-serif;
}

body {
  background: #fbfff1;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
