@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet);
div#error-div{
    padding:10%;
}

div#error-div h2#error-message-div, div#error-div h3#go-back-home-div{

    text-align: center;
}
.NotFound {
    padding-top: 100px;
    text-align: center;
}
.App a.brand-image-link{
  width: 120px;
}

.App .body {
  min-height: 1000px;
  font-size: 1rem;
}

.App .nav-item{
  /* margin: auto; */
  /* margin-right: 30px;
  margin-left: 30px; */
  font-size: 1em;
  cursor: pointer;
  color: #2d348a;
  -webkit-text-decoration-color: #707db7;
          text-decoration-color: #707db7;
}

.h1, h1 {
  font-size: 2rem;
  text-align: center;
}
.h2, h3, h4, h5, h6 {
  font-size: 1rem;
  text-align: center;
}

#navbar-div .nav-link{
  padding: 0;
}

.navbar .brand-image-link{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 60%;
}

.carousel-indicators li{
  height: 5px;
}

.footer {
    color: black;
    height: 5%;
    display: -webkit-flex;
    display: flex;
    background-color: #4e58a1;
    height: auto;
}

.App .navbar{
  width: 100%;
  background-repeat: no-repeat;
  display: -webkit-flex;
  display: flex;
  /* background-image: url("./bannerlogo.svg"); */
  background-size: contain;
  background-color: #b4c5e4;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  font-weight: bolder;
  -webkit-justify-content: center;
          justify-content: center;
  /* height: 60px; */
}

.navbar-nav
{
  width: 35%;
}

iframe{
  display: none;
}

.App .navbar-collapse {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  /* height: 100%; */
}

.App .navbar-toggler {
  position: absolute;
  right: 0;
}

.Home .card {
  margin: 5px;
  min-width: 50px;
  max-width: 200px;
}

.spinning {
  scale: 3;
  display: inline;
  margin-right: 15px;
  top: 0;
  margin: 15%;
}


.dropdown-menu > *{
  font-weight: bolder;
  color: #2d348a;
}

.dropdown-menu {
  margin: 0px;
  background-color: transparent;
  border: none;
 }

@media only screen and (min-width:780px){
  .App {
    width: 100%;
    background-color: #fbfff1;
    font-family: 'Poppins', sans-serif;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .navbar-nav
  {
    width: unset;
  }

  .App .nav-item{
    margin: auto;
    margin-right: 30px;
    margin-left: 30px;
    font-size: 1em;
    cursor: pointer;
    color: #2d348a;
    -webkit-text-decoration-color: #707db7;
            text-decoration-color: #707db7;
  }

  #navbar-div .dropdown-toggle{
    color: #2d348a;
  }

  .App a.brand-image-link{
    width: 120px;
  }

  .App .logged-in-options{
    display: -webkit-flex;
    display: flex;
  }

  .dropdown-menu {
    background-color: white;
   }

  .App .market-links-container .nav-item{
    margin-right: 90px;
    margin-top: 53px;
    color: #2d348a;
  }

  .App .navbar-collapse{
    -webkit-justify-content: center;
            justify-content: center;
  }

  .App .navbar-brand{
    -webkit-flex: content;
            flex: content;
  }

  .App .navbar-toggler {
    float:right;
  }


  .loading-page-spinner{
    margin: 25%;
    scale: 10;
  }
}

.spinning {
  /* margin-right: 7px; */
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
  /* -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1.0, 1.0); */
  /* scale: 5; */
}
@-webkit-keyframes spin{
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg);}
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg);}
}
@keyframes spin{
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg);}
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg);}
}


/* Alt front page? */

/* .App .navbar { */
  /*! width: 100%; */
  /*! background-color: #b4c5e4; */
  /* background-image: url("./bannerlogo.svg"); */
  /* background-repeat: no-repeat;
  background-size: contain;

  font-family: 'Alegreya', serif;
  text-align: center;
  font-weight: bolder;
} */


  /* .App a.brand-image-link {
    width: 15%;
    margin-left: 55px;
    height: 100%;
  } */

  /* .boats {
    margin-right: 15%;
    margin-left: 15%;
    margin-top: 5%;
   } */

   /* .navbar-collapse
   { */
    /*! justify-content: right; */
    /*! margin-right: 15%; */
    /* width: 70%;
    margin-top: 1%;
   }
    */

    /* img { */
      /*! height: auto; */
      /* width: 100%;
      vertical-align: none;
      object-fit: contain;
  } */



  /* hr{
    width: 95%;
  } */

  /* .navbar-collapse { */
    /*! justify-content: right; *//*! margin-right: 15%; */
    /* width: 70%;
    margin-top: 1%;
    margin-left: 5%;
   } */






select.form-control,
textarea.form-control,

input.form-control {
  font-size: small;
  font-family: 'Poppins', sans-serif;

}

label.control-label{
  font-size:small;
}

input[type=file]{
  width: 100%;
}

h1,h2,h3,h4,h5,h6,label,span {
  font-family: 'Poppins', sans-serif;
}

body {
  background: #fbfff1;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

